var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('BaseNotificationsDrawer', {
    attrs: {
      "notifications": _vm.notifications
    },
    on: {
      "input": _vm.notificationInput
    },
    model: {
      value: _vm.notificationDrawer,
      callback: function callback($$v) {
        _vm.notificationDrawer = $$v;
      },
      expression: "notificationDrawer"
    }
  }), _c('v-app-bar', {
    staticClass: "d-none d-md-block d-print-none",
    attrs: {
      "data-cy": "appbar-adviser-desktop",
      "app": "",
      "color": "indigo",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-crm-desktop"
    },
    on: {
      "click": _vm.crm
    }
  }, [_vm._v(" CRM ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-clients-desktop"
    },
    on: {
      "click": _vm.clients
    }
  }, [_vm._v(" Clients ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-profile-desktop"
    },
    on: {
      "click": _vm.profile
    }
  }, [_vm._v(" Profile ")]), _vm.isAdmin ? _c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-profile-desktop"
    },
    on: {
      "click": _vm.advisers
    }
  }, [_vm._v(" Advisers ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-profile-desktop"
    },
    on: {
      "click": _vm.settings
    }
  }, [_vm._v(" Project Settings ")]), _c('v-spacer'), _c('v-badge', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.notifications.length,
      expression: "notifications.length"
    }],
    attrs: {
      "transition": "fade-transition",
      "content": _vm.notifications.length,
      "value": _vm.notifications.length,
      "overlap": "",
      "color": "warning"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.notificationDrawer = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-bell")])], 1)], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "data-cy": "btn-logout-desktop"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Logout ")])], 1), _c('v-app-bar', {
    staticClass: "d-md-none d-print-none",
    attrs: {
      "data-cy": "appbar-adviser-mobile",
      "app": "",
      "dark": "",
      "color": "indigo"
    }
  }, [_c('v-menu', {
    attrs: {
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item-group', [_c('v-list-item', {
    on: {
      "click": _vm.crm
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-format-list-text")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("CRM")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.clients
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account-multiple")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Clients")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.profile
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Profile")])], 1)], 1), _vm.isAdmin ? _c('v-list-item', {
    on: {
      "click": _vm.advisers
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account-multiple-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Advisers")])], 1)], 1) : _vm._e(), _c('v-list-item', {
    on: {
      "click": _vm.settings
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-cog-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Project Settings")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-logout")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 1)], 1)], 1)], 1), _c('v-badge', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.notifications.length,
      expression: "notifications.length"
    }],
    staticClass: "bell-mobile",
    attrs: {
      "transition": "fade-transition",
      "content": _vm.notifications.length,
      "value": _vm.notifications.length,
      "overlap": "",
      "color": "warning"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.notificationDrawer = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-bell")])], 1)], 1)], 1), _c('v-main', [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }