<template>
  <v-app>
    <BaseNotificationsDrawer
      :notifications="notifications"
      v-model="notificationDrawer"
      @input="notificationInput"/>
    <!--    DESKTOP APP BAR-->
    <v-app-bar
      data-cy="appbar-adviser-desktop"
      class="d-none d-md-block d-print-none"
      app
      color="indigo"
      dark
    >
      <v-btn text @click="crm" data-cy="btn-crm-desktop">
        CRM
      </v-btn>
      <v-btn text @click="clients" data-cy="btn-clients-desktop">
        Clients
      </v-btn>
      <v-btn text @click="profile" data-cy="btn-profile-desktop">
        Profile
      </v-btn>
      <v-btn v-if="isAdmin" text @click="advisers" data-cy="btn-profile-desktop">
        Advisers
      </v-btn>
      <v-btn text @click="settings" data-cy="btn-profile-desktop">
        Project Settings
      </v-btn>
      <v-spacer></v-spacer>
      <v-badge
        transition="fade-transition"
        v-show="notifications.length"
        :content="notifications.length"
        :value="notifications.length"
        overlap
        color="warning"
      >
        <v-btn
          icon
          small
          @click="notificationDrawer=true"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
      <v-btn text @click="logout" data-cy="btn-logout-desktop">
        Logout
      </v-btn>
    </v-app-bar>

    <!--    MOBILE APP BAR-->
    <v-app-bar
      data-cy="appbar-adviser-mobile"
      app
      dark
      class="d-md-none d-print-none"
      color="indigo"
    >
      <!--      <v-app-bar-nav-icon><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>-->
      <!--      <v-app-bar-title>CRM Name here</v-app-bar-title>-->
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>

            <v-list-item @click="crm">
              <v-list-item-icon>
                <v-icon>mdi-format-list-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>CRM</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="clients">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isAdmin" @click="advisers">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Advisers</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="settings">
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Project Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-badge
        class="bell-mobile"
        transition="fade-transition"
        v-show="notifications.length"
        :content="notifications.length"
        :value="notifications.length"
        overlap
        color="warning"
      >
        <v-btn
          icon
          small
          @click="notificationDrawer=true"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import {
  getCurrentUser,
  logout,
  notificationSnapshot,
  getAdminRole,
} from '@/services/firebase';
import BaseNotificationsDrawer from '@/components/BaseNotificationsDrawer.vue';

export default {
  name: 'DashboardAdviserLayout',
  components: { BaseNotificationsDrawer },
  data() {
    return {
      isAdmin: false,
      notificationDrawer: false,
      notifications: [],
    };
  },
  async mounted() {
    this.isAdmin = await getAdminRole();

    const logMsg = (data) => {
      this.notifications = [];
      data.forEach((doc) => {
        this.notifications.push({
          content: doc.data(),
          ref: doc.id,
        });
      });

      this.notifications.sort((a, b) => b.content.created.seconds
      - a.content.created.seconds);
    };

    this.SET_MESSAGE_LISTENER(notificationSnapshot(getCurrentUser().uid, logMsg));
  },
  methods: {
    ...mapMutations(['SET_APPLICATION_DATA', 'SET_APPLICATION_MODEL', 'SET_MESSAGE_LISTENER']),
    ...mapActions(['unsubscribeMessageListener']),
    crm() {
      if (this.$route.name !== 'AdviserCRM') {
        this.$router.push({ name: 'AdviserCRM' });
      }
    },
    clients() {
      if (this.$route.name !== 'UsersIndex') {
        this.$router.push({ name: 'UsersIndex' });
      }
    },
    profile() {
      if (this.$route.name !== 'AdviserProfile') {
        this.$router.push({ name: 'AdviserProfile' });
      }
    },
    advisers() {
      if (this.$route.name !== 'AdvisersIndex') {
        this.$router.push({ name: 'AdvisersIndex' });
      }
    },
    settings() {
      if (this.$route.name !== 'Settings') {
        this.$router.push({ name: 'Settings' });
      }
    },
    logout() {
      logout();
      this.$router.push({ name: 'Login' });
      this.unsubscribeMessageListener();
    },
    notificationInput() {
      this.notificationDrawer = false;
    },
  },

  watch: {
    notifications(val) {
      this.$store.commit('SET_LAST_NOTIFICATION', val[0]);
    },
  },
};
</script>

<style scoped>
.bell-mobile {
  margin-left: auto;
}
</style>
