<template>
  <v-navigation-drawer
    temporary
    right
    app
    v-model="currentValue"
    @input="onInput"
    width="400"
  >
    <v-toolbar color="info" dark>
      <v-toolbar-title>Notifications</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeNotification">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-divider></v-divider>
      <v-card
        class="ma-3"
        v-for="msg in notifications"
        :key="msg.ref">
        <v-card-title>{{ msg.content.title }}</v-card-title>
        <v-card-subtitle>{{
            msg.content.created.toDate()
              .toLocaleString()
          }}
        </v-card-subtitle>
        <v-card-text>
          {{ msg.content.msg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clickDismiss(msg.ref)">DISMISS</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import { dismissNotification } from '@/services/firebase';

export default {
  name: 'BaseNotificationsDrawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentValue: false,
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    },
  },
  methods: {
    clickDismiss(notificationId) {
      dismissNotification(notificationId)
        .then(() => {
          if (this.notifications.length === 0) this.$emit('input', false);
        });
    },
    onInput() {
      if (!this.currentValue) this.$emit('input', this.currentValue);
    },
    closeNotification() {
      this.$emit('input', false);
      this.currentValue = false;
    },
  },
};
</script>
