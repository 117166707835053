var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "temporary": "",
      "right": "",
      "app": "",
      "width": "400"
    },
    on: {
      "input": _vm.onInput
    },
    model: {
      value: _vm.currentValue,
      callback: function callback($$v) {
        _vm.currentValue = $$v;
      },
      expression: "currentValue"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "color": "info",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Notifications")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeNotification
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-container', [_c('v-divider'), _vm._l(_vm.notifications, function (msg) {
    return _c('v-card', {
      key: msg.ref,
      staticClass: "ma-3"
    }, [_c('v-card-title', [_vm._v(_vm._s(msg.content.title))]), _c('v-card-subtitle', [_vm._v(_vm._s(msg.content.created.toDate().toLocaleString()) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(msg.content.msg) + " ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickDismiss(msg.ref);
        }
      }
    }, [_vm._v("DISMISS")])], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }